/*
 * Routing Utils
 */

export const PATHS = {
  home: '/',
  outsourcing: '/outsourcing',
  design: '/design',
  portfolio: '/portfolio',
  company: '/company',
  contacts: '/contacts',
  articles: '/articles',
  policy: '/policy',
}

export const getProjectSlug = (_slug: string = '') => {
  const digits = Array.from(Array(10).keys()).map(String)
  const lastChar = _slug[_slug.length - 1]

  if (digits.includes(lastChar)) {
    return _slug.split('-').slice(0, -1).join('-')
  }

  return _slug
}

export const ROUTES = {
  project: (slug: string) => `${PATHS.portfolio}/${getProjectSlug(slug)}`,
}
