/*
 * Animations
 */

type Params = { delay?: number; index?: number; duration?: number }
export type FadeDirection = 'up' | 'left' | 'right'

export const ANIM_DELAY = 100

export const animations = (params?: { name?: string } & Params) => ({
  'data-aos': params?.name,
  'data-aos-delay': params?.delay || (params?.index || 0) * ANIM_DELAY || 0,
  'data-aos-duration': params?.duration,
})

export const fade = (params: Params = {}) => {
  return animations({ name: 'fade', ...params })
}

export const fadeDirection = (direction: FadeDirection, params: Params = {}) => {
  return animations({ name: `app-fade-${direction}`, ...params })
}

export const fadeUp = (params: Params = {}) => {
  return fadeDirection('up', params)
}

export const fadeLeft = (params: Params = {}) => {
  return fadeDirection('left', params)
}

export const fadeRight = (params: Params = {}) => {
  return fadeDirection('right', params)
}
