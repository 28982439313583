import React, { forwardRef, Ref, useMemo } from 'react'
import classNames from 'classnames'
import cn from 'classnames/bind'
import BasicSpinner from 'Components/spinners/BasicSpinner'
import type { TouchableProps } from 'Components/touchables/Touchable'
import Touchable from 'Components/touchables/Touchable'
import type { SvgIconType } from 'Components/SvgIcon/SvgIcon'
import SvgIcon from 'Components/SvgIcon/SvgIcon'
import lodashSize from 'lodash/size'
import styles from './Button.module.scss'

const cx = cn.bind(styles)

export type ButtonTheme = 'base' | 'alt' | 'secondary'

export type ButtonProps = {
  className?: string
  titleClassName?: string
  iconClassName?: string
  title?: React.ReactNode
  disabled?: boolean
  clickDisabled?: boolean
  stretch?: boolean
  isFetching?: boolean
  variant?: 'filled' | 'outline' | 'flat'
  theme?: ButtonTheme
  size?: 'base' | 'small'
  iconStart?: null | SvgIconType
  iconEnd?: null | SvgIconType
  renderAtEnd?: () => React.ReactNode
  children?: React.ReactNode
  rounded?: boolean
} & TouchableProps

const Button = (props: ButtonProps, ref: Ref<any>) => {
  const {
    className = '',
    titleClassName = '',
    iconClassName = '',
    title = '',
    disabled = false,
    clickDisabled = false,
    stretch = false,
    isFetching = false,
    variant = 'filled',
    theme = 'base',
    size = 'base',
    iconStart = null,
    iconEnd = null,
    renderAtEnd = null,
    children = null,
    rounded = false,
    ...other
  } = props

  const _className = useMemo(() => {
    return classNames([
      styles.root,
      className,
      cx(variant),
      cx(theme),
      size === 'small' ? styles.small : styles.basic,
      {
        [styles.disabled]: disabled,
        [styles.clickDisabled]: clickDisabled,
        [styles.stretch]: stretch,
        [styles.fetching]: isFetching,
        [styles.rounded]: rounded,
        [styles.withTitle]: lodashSize(title) > 0,
      },
    ])
  }, [
    className,
    clickDisabled,
    disabled,
    isFetching,
    rounded,
    variant,
    theme,
    size,
    title,
    stretch,
  ])

  return (
    <Touchable
      {...other}
      ref={ref}
      className={_className}
      disabled={clickDisabled || disabled || isFetching}
      styling="clear"
    >
      {iconStart && !isFetching && (
        <SvgIcon
          className={classNames([styles.icon, styles.iconStart, iconClassName])}
          Icon={iconStart}
        />
      )}
      {isFetching ? (
        <BasicSpinner className={styles.spinner} size={20} />
      ) : (
        title && (
          <span className={classNames([styles.title, titleClassName])}>{title}</span>
        )
      )}
      {iconEnd && !isFetching && (
        <SvgIcon
          className={classNames([styles.icon, styles.iconEnd, iconClassName])}
          Icon={iconEnd}
        />
      )}
      {children}
      {renderAtEnd && renderAtEnd()}
    </Touchable>
  )
}

export default forwardRef(Button)
