/*
 * Common Utils
 */
import {
  DependencyList,
  Dispatch,
  SetStateAction,
  useCallback,
  useLayoutEffect,
  useState,
} from 'react'
import { Swiper as SwiperClass } from 'swiper'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import { ArticleFragment } from 'Typings/generated-gatsby-types'
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next'
import { useMediaMatches } from 'Utils/mq'
import { size } from 'lodash'
import { PATHS } from 'Utils/routing'
import { requestCreateOrder, RequestCreateOrderResponse } from 'Utils/network/requests'
import { HttpResponseSuccess } from 'Utils/network/HttpClient'
import { Snackbars } from 'Config/snackbars'
import HttpResponseError from 'Utils/network/HttpResponseError'
import { InputCallbackData } from 'Components/inputs/Input/Input'

export const useSliderProgress = ({
  onSlideChange,
  initialSlide,
}: {
  onSlideChange?: (newIndex: number, swiper: SwiperClass) => void
  initialSlide?: number
} = {}) => {
  const [index, setIndex] = useState<number>(initialSlide || 0)

  const _onSlideChange = useCallback(
    (swiper: SwiperClass) => {
      setIndex(swiper.activeIndex)
      onSlideChange && onSlideChange(swiper.activeIndex, swiper)
    },
    [onSlideChange],
  )

  return { slideIndex: index, onSlideChange: _onSlideChange }
}

type ScrollPosition = {
  x: number
  y: number
}
export const usePageScrollPosition = (
  callback: (props: { prevPos: ScrollPosition; currPos: ScrollPosition }) => void,
  deps: DependencyList = [],
  wait = 100,
) => {
  useScrollPosition(callback, deps, undefined, false, wait)
}

export function viewport() {
  const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
  const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)

  return { width: vw, height: vh }
}

export const isEnCopy = () => window.location.host === 'good-factory.net'

export const useMaybeChangeLanguageOnInit = () => {
  const [isInit, setIsInit] = useState(false)

  const { languages, language, changeLanguage } = useI18next()

  useLayoutEffect(() => {
    if (isEnCopy()) {
      changeLanguage('en')
      window.localStorage.setItem('gatsby-i18next-language', 'en')
      // Добавляем задержку, чтобы не мелькал экран с русским языком
      setTimeout(() => setIsInit(true), 350)
      return
    }

    const savedLanguage = window.localStorage.getItem('gatsby-i18next-language')

    if (savedLanguage !== language && languages.includes(language)) {
      window.localStorage.setItem('gatsby-i18next-language', language)
    }

    setIsInit(true)
  }, [])

  return isInit
}

export const sortByDateDesc = <T extends Record<string, any>>(
  items: T[],
  key: keyof T,
): T[] => {
  return items.sort((a, b) => new Date(b[key]).getTime() - new Date(a[key]).getTime())
}

export type ArticlesData = { edges: Array<{ node: ArticleFragment }> }

export const formatArticles = (data: ArticlesData): Array<ArticleFragment> => {
  return (data.edges || []).map(({ node }) => node)
}

export const getSortedArticles = (
  data: ArticlesData,
  filter?: (article: ArticleFragment, index?: number) => boolean,
): Array<ArticleFragment> => {
  const formatted = formatArticles(data)
  return sortByDateDesc(filter ? formatted.filter(filter) : formatted, 'created_at')
}

export const formatDate = (date: string): string => {
  return new Date(date)
    .toLocaleDateString('ru-RU', { day: '2-digit', month: '2-digit', year: 'numeric' })
    .replace(/\//g, '.')
}

export const getShortArticleContent = ({ content }: ArticleFragment) => {
  if (size(content) === 0) {
    return ''
  }

  let newContent = content

  if (['#', '*'].includes(content[0])) {
    // Обрезаем стартовый заголовок
    const parts = content.split('\n')
    const textIndex = parts.findIndex((text: string, index: number) => {
      return index > 0 && Boolean(text)
    })

    newContent = parts.slice(textIndex).join('\n')
  }

  return newContent.slice(0, 300)
}

export const getShortArticleContentForSEO = (article: ArticleFragment) => {
  // Убираем последнее обрезанное слово и добавляем троеточие
  return getShortArticleContent(article).split(' ').slice(0, -1).join(' ') + '...'
}

export const getArticleLink = (slug: string) => {
  const digits = Array.from(Array(10).keys()).map(String)
  const lastChar = slug[slug.length - 1]

  const formattedSlug = digits.includes(lastChar)
    ? slug.split('-').slice(0, -1).join('-')
    : slug

  return [PATHS.articles, formattedSlug].join('/')
}

export const scrollToElem = (elem: HTMLDivElement | null, offset: number = 0) => {
  window.scrollBy({
    top: (elem?.getBoundingClientRect()?.top || 0) - offset,
    behavior: 'smooth',
  })
}

export type OrderFormType = {
  name: string
  contact: string
  submit: string
  text: string
}

export type OrderType = {
  success: string
  error: string
  form: OrderFormType
}

export type ContactFormParams = {
  withCheckbox?: boolean
  onSubmit?: () => void
}

export const useContactForm = (params: ContactFormParams) => {
  const { withCheckbox = true, onSubmit } = params || {}

  const [isFetchingForm, setIsFetchingForm] = useState(false)
  const [name, setName] = useState('')
  const [contact, setContact] = useState('')
  const [text, setText] = useState('')
  const [isChecked, setIsChecked] = useState(true)

  const isSmallDevice = useMediaMatches({ maxWidth: 600 /* Как в CSS */ })
  const isMediumDevice = useMediaMatches({ maxWidth: 1024 /* Как в CSS */ })

  const [t] = useTranslation()

  const order = t('order', { returnObjects: true }) as OrderType
  const { form = {} as OrderFormType } = order || {}

  const formSubmit = () => {
    setIsFetchingForm(true)
    requestCreateOrder({ name: name.trim(), contact: contact.trim(), text: text.trim() })
      .then((r: HttpResponseSuccess<RequestCreateOrderResponse>) => {
        setName('')
        setContact('')
        setText('')

        setIsFetchingForm(false)

        Snackbars.enqueue(order.success, { variant: 'success' })

        onSubmit?.()
        return r
      })
      .catch((e: HttpResponseError) => {
        Snackbars.enqueue(e?.message || order.error, { variant: 'error' })
        setIsFetchingForm(false)
      })
  }

  const getCallback = (func: Dispatch<SetStateAction<string>>) => {
    return ({ value }: InputCallbackData) => func(value)
  }

  const hasData = Boolean(name.trim() && contact.trim())
  const isButtonEnabled = hasData && (!withCheckbox || isChecked)

  return {
    t,
    isSmallDevice,
    isMediumDevice,
    form,
    name,
    contact,
    text,
    isFetchingForm,
    isButtonDisabled: !isButtonEnabled,
    isChecked,
    onChangeName: getCallback(setName),
    onChangeContact: getCallback(setContact),
    onChangeText: getCallback(setText),
    onCheck: setIsChecked,
    formSubmit,
  }
}
