import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { isString } from 'lodash'
import classNames from 'classnames'
import { Collapse, Dialog } from '@material-ui/core'
import Slide from '@material-ui/core/Slide'
import { TransitionProps } from '@material-ui/core/transitions'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { StringParam, useQueryParam } from 'use-query-params'
import BaseContainer from 'Components/BaseContainer/BaseContainer'
import Touchable from 'Components/touchables/Touchable'
import Button from 'Components/touchables/Button'
import Text from 'Components/texts/Text'
import Row from 'Components/Row/Row'
import { fadeUp } from 'Utils/animations'
import ChevronLeft from 'Assets/icon-chevron-left.inline.svg'
import styles from './Menu.module.scss'

export type MenuItem = { id?: string; title: string; link?: string; sub?: MenuItem[] }
type Subs = null | { items: MenuItem[]; level: string }

export const DEFAULT_MENU_OPEN_STATE = 'open'

export const useMenu = () => {
  const [state, setState] = useQueryParam('menu', StringParam)

  return {
    isMenuOpen: Boolean(state),
    menuState: state,
    setMenuState: (value?: string | boolean) => {
      setState(isString(value) ? value : value ? DEFAULT_MENU_OPEN_STATE : undefined)
    },
  }
}

const Transition = React.forwardRef(
  (props: TransitionProps & { children?: React.ReactElement<any, any> }, ref) => {
    return <Slide direction="up" ref={ref} {...props} />
  },
)

type SetSubs = (subs: Subs) => void

const Menu = () => {
  const { isMenuOpen, menuState: state, setMenuState: setState } = useMenu()

  const { t } = useTranslation()

  const [subs, setSubs] = useState<Subs>(null)
  const [subsVisible, setSubsVisible] = useState<boolean>(false)
  const [mobileSubOpen, setMobileSubOpen] = useState<boolean>(false)

  const _menu = t('menu.items', { returnObjects: true }) as MenuItem[]
  const menu = Array.isArray(_menu) ? _menu : []

  useEffect(() => {
    if (state && state !== DEFAULT_MENU_OPEN_STATE) {
      const activeSubItem = menu.find((i) => Boolean(i.sub) && i.id === state)
      if (activeSubItem) {
        setSubs({ items: activeSubItem.sub || [], level: 'base' })
        setSubsVisible(true)
      }
    }
  }, [state])

  const _setSubs = useCallback((s: Subs) => {
    const visible = Boolean(s)
    setSubsVisible(visible)

    if (s) {
      setSubs(s)
    }
  }, [])

  const onClickContent = useCallback(() => {
    setSubsVisible(false)
    setSubs(null)
  }, [])

  const onClick = () => {
    setMobileSubOpen(false)
    onClickContent()
  }

  return (
    <>
      <Helmet>{state && <html data-menu-open={state} />}</Helmet>
      <Dialog
        fullScreen
        open={isMenuOpen}
        onClose={() => setState(false)}
        onExited={() => setSubs(null)}
        TransitionComponent={Transition}
      >
        <div className={styles.content} onClick={onClick}>
          <BaseContainer className={styles.menuContent} vertical="none">
            <Row vertical="top">
              <MenuItems
                items={menu}
                level="base"
                subs={subs}
                subsVisible={subsVisible}
                setSubs={_setSubs}
                onClickContent={onClickContent}
                mobileSubOpen={mobileSubOpen}
                setMobileSubOpen={setMobileSubOpen}
              />
              {subs !== null && (
                <MenuItems
                  className={classNames([
                    styles.subDesktop,
                    { [styles.subDesktopVisible]: subsVisible },
                  ])}
                  level="child"
                  items={subs.items}
                  subs={subs}
                  subsVisible={subsVisible}
                  setSubs={_setSubs}
                  onClickContent={onClickContent}
                  mobileSubOpen={mobileSubOpen}
                  setMobileSubOpen={setMobileSubOpen}
                />
              )}
            </Row>
          </BaseContainer>
        </div>
      </Dialog>
    </>
  )
}

export type MenuItemsProps = {
  className?: string
  items: MenuItem[]
  level: string
  subs: Subs
  subsVisible: boolean
  setSubs: SetSubs
  onClickContent: () => void
  mobileSubOpen: boolean
  setMobileSubOpen: Dispatch<SetStateAction<boolean>>
}

const MenuItems = (props: MenuItemsProps) => {
  const {
    className = '',
    items,
    level,
    subs,
    subsVisible,
    setSubs,
    onClickContent,
    mobileSubOpen,
    setMobileSubOpen,
  } = props

  return (
    <div className={classNames([styles.menuItems, className])}>
      {items.map((item, index) => (
        <MenuItemRenderer
          key={item.title}
          item={item}
          index={index}
          level={level}
          subs={subs}
          subsVisible={subsVisible}
          setSubs={setSubs}
          onClickContent={onClickContent}
          mobileSubOpen={mobileSubOpen}
          setMobileSubOpen={setMobileSubOpen}
        />
      ))}
    </div>
  )
}

export type MenuItemRendererProps = {
  item: MenuItem
  index: number
  level: string
  subs: Subs
  subsVisible: boolean
  setSubs: SetSubs
  onClickContent: () => void
  mobileSubOpen: boolean
  setMobileSubOpen: Dispatch<SetStateAction<boolean>>
}

const MenuItemRenderer = (props: MenuItemRendererProps) => {
  const {
    item,
    index,
    level,
    subs,
    subsVisible,
    setSubs,
    onClickContent,
    mobileSubOpen,
    setMobileSubOpen,
  } = props

  const isActive = subsVisible && subs?.items && item.sub === subs?.items
  const isInactive = subsVisible && !isActive && subs?.level === level

  const isOpen = Boolean(item.sub && (isActive || mobileSubOpen))

  const onClick = () => {
    if (item.sub) {
      setSubs(isActive ? null : { items: item.sub || [], level })
      setMobileSubOpen((p) => {
        if (p) {
          onClickContent()
        }

        return !p
      })
    }
  }

  // Анимации применяются к другому элементу, т.к. иначе возникают проблемы с aos при изменении классов isActive/isInactive
  return (
    <div className={styles.menuItemContainer} {...fadeUp({ index })}>
      <Touchable
        className={classNames([
          styles.menuItem,
          {
            [styles.menuItemActive]: isActive,
            [styles.menuItemInactive]: isInactive,
            [styles.open]: isOpen,
          },
        ])}
        link={item.link}
        styling="clear"
        onClick={onClick}
      >
        <Row className={styles.menuItemRow}>
          {item.sub && (
            <Button
              className={styles.activeIcon}
              iconClassName={styles.activeIconSvg}
              iconStart={ChevronLeft}
              variant="outline"
              theme="alt"
            />
          )}
          <Row className={styles.menuItemRowInner}>
            <Text className={styles.menuItemText} variant="heading">
              {item.title}
            </Text>
            {item.sub && (
              <Button
                className={styles.subIcon}
                iconStart={ChevronLeft}
                variant="flat"
                theme="alt"
              />
            )}
          </Row>
        </Row>
      </Touchable>
      {item.sub && (
        <Collapse
          className={styles.subMenuCollapse}
          in={isOpen}
          onExited={() => setMobileSubOpen(false)}
        >
          <MenuItems
            className={styles.subMobile}
            level="child-mobile"
            items={item.sub}
            subs={subs}
            setSubs={setSubs}
            subsVisible={true}
            onClickContent={onClickContent}
            mobileSubOpen={mobileSubOpen}
            setMobileSubOpen={setMobileSubOpen}
          />
        </Collapse>
      )}
    </div>
  )
}

export default Menu
